import React from 'react'

function Disclaimer() {
    return (
        <>
            <h2>Rechtliche Hinweise zur Internetpräsenz (Disclaimer)</h2>


            <h1>Hinweise zum Datenschutz</h1>
            <p>
                Die Stiftung Haus Pius XII freut sich über Ihren Besuch auf unserer Webseite sowie über ihr Interesse an unserer Einrichtung und unseren Angeboten für die Kinder- und Jugendhilfe. Den Schutz Ihrer privaten Daten nehmen wir dabei sehr ernst, insbesondere wenn Sie mit uns über das Internet in Kontakt treten. Wir sind bestrebt, Ihnen ein umfassendes Online-Angebot zur Verfügung zu stellen und dabei den Schutz Ihrer Privatsphäre zu gewährleisten. Als gesetzliche Grundlagen dienen insbesondere das Bundesdatenschutzgesetz (BDSG) und das Telemediengesetz (TMG).
            </p>


            <h1>Umgang mit vertraulichen Daten</h1>
            <p>
                Die Bekanntgabe von personenbezogenen Daten ist Ihnen weitest gehend freigestellt. Personenbezogene Daten sind beispielsweise Ihr Name, Ihre Anschrift, Ihre E-Mail-Adresse und Ihre Telefonnummer. Bitte beachten Sie, dass solche Daten, beispielsweise zur Bearbeitung Ihrer Anfrage, in gewissem Umfange erforderlich sind. Sollten Sie uns personenbezogene Daten bekannt geben, werden wir diese Daten ausschließlich zum Zweck der Erledigung Ihrer Anfrage verwenden. Wir werden Ihre personenbezogenen Daten nicht an Dritte außerhalb der Stiftung Haus Pius XII verkaufen, verleihen oder in sonstiger Weise weitergeben. Sie können jederzeit Auskunft über die zu Ihrer Person gespeicherten Daten oder deren Löschung verlangen.
            </p>
            <p>
                Die Stiftung Haus Pius XII arbeitet mit Datennetzen, die mit Firewalls geschützt sind. Eine Verschlüsselung der jeweils übertragenen Informationen erfolgt jedoch nicht.
            </p>
            <p>
                Zur optimalen Gestaltung unsere Webseiten setzen wir in bestimmten Bereichen wie beispielsweise auf den Supportseiten Session-IDs ein. Mit Hilfe einer Session-ID (engl. session identity) erkennen wir mehrere zusammengehörige Anfragen von Ihnen. Session-IDs sind vergleichbar mit sogenannten Cookies, kleinen Dateien, die auf Ihrer Festplatte abgelegt werden. Im Unterschied zu Cookies zerstören sich Session-IDs spätestens nach Schließen des Browsers von selbst.
            </p>
            <p>
                Natürlich können Sie unsere Website auch ohne Session-ID betrachten. Die Hilfe-Funktion in der Menüleiste der meisten Webbrowser erläutert die Einstellungen, die Sie dazu vornehmen müssen. Wenn Sie keine Session-ID akzeptieren, kann dieses aber zu Funktionseinschränkungen unserer Angebote führen.
            </p>
            <p>
                Unabhängig davon wird jeder Zugriff auf unseren Web-Server anonymisiert (d.h. ohne Personenbezug) protokolliert. Wir verwenden solche anonymen Daten ausschließlich für statistische Zwecke.
            </p>


            <h1>Querverweise (Links)</h1>
            <p>
                Die Website der Stiftung Haus Pius XII enthält auch Querverweise (Links) zu Websites anderer Anbieter. Bei der ersten Verknüpfung wurde das fremde Angebot auf rechtswidrige Inhalte überprüft. Links zu anderen Websites führen dazu, dass Sie die Website der Stiftung Haus Pius XII verlassen. Die mit Links verbundenen Websites stehen nicht unter der Kontrolle der Stiftung Haus Pius XII. Für die Inhalte, Änderungen oder Updates solcher Websites kann die Stiftung Haus Pius XII daher keine Gewährleistung übernehmen. Diese Links werden lediglich als Serviceleistung angeboten. Soweit jedoch die Stiftung Haus Pius XII feststellt oder darauf hingewiesen wird, dass ein Angebot einen rechtswidrigen Inhalt aufweist, wird dieser Querverweis (Link) unverzüglich geprüft und gegebenenfalls aufgehoben.
            </p>


            <h1>Informationen zur Online-Streitbeilegung</h1>
            <p>
                Die EU-Kommission hat eine Internetplattform zur Online-Beilegung von Streitigkeiten (sog. „OS-Plattform“) geschaffen. Die OS-Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten, die aus Online-Dienstleistungsverträgen erwachsen. Die OS-Plattform erreichen Sie <a className="fancy-link" target="_blank" rel="noreferrer" href="http://ec.europa.eu/consumers/odr/">hier</a>.
            </p>
            <br/><br/>
            <b>Außergerichtliche Streitschlichtung:</b>
            <br/>
            <p>
                Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle nach dem „Gesetz über die alternative Streitbeilegung in Verbrauchersachen (VSBG)“ sind wir nicht bereit und nicht verpflichtet.
            </p>


            <h1>Unsere Haftung</h1>
            <p>
                Die auf dieser Website enthaltenen Informationen wurden auf ihre Richtigkeit eingehend geprüft. Die Stiftung Haus Pius XII kann jedoch keine Verantwortung dafür übernehmen, dass die Informationen dem von den Nutzern gewünschten Zweck entsprechen. Der Zugang zu unsere Website und der Gebrauch der dort angebotenen Dokumente erfolgt auf eigenes Risiko des Nutzers. Die Dokumente und die damit verbundenen Grafiken, die auf dieser Website veröffentlicht werden, können technische oder typografische Fehler enthalten.
            </p>


            <h1>Sonstige Hinweise</h1>
            <p>
                Durch die Digitalisierung von Daten können Fehler auftreten. Beim Einsatz unterschiedlicher Browser für das World-Wide-Web und aufgrund unterschiedlicher Software-Einstellungen kann es bei der Darstellung der Daten zu Abweichungen kommen. Das dem Informationsangebot zugrunde liegende Originalmaterial kann gegebenenfalls bei der Stiftung Haus Pius XII angefordert werden.
            </p>
            <p>
                Alle Angaben zu Objekten, Personen und den damit verbundenen Darstellungen im Rahmen unserer Internetpräsenz, inclusive des Bild-, Ton- und Videomaterials sind in schriftlicher Form bis auf Widerruf abgeklärt. Zur Vermeidung von Urheberrechtsverletzungen und des Rechtes auf die Wahrung des Persönlichkeitsrechtes liegen uns die entsprechenden Genehmigungen vor.  
            </p>
            <p>
                Die inhaltlichen Ausführungen auf den verschiedenen Seiten unseres Internetauftrittes sind geistiges Eigentum der Stiftung Haus Pius XII und dürfen nur mit ausdrücklicher Zustimmung des Vorstandes der Stiftung Haus Pius XII verwendet werden. Dieses gilt vor allem für die angebotenen Downloads der Konzeptionen, wie auch für den Auszug (Hardcopy) aus Texten und Bildern aus unserer Internetpräsenz.
            </p>
            <p>
                Wir behalten uns vor, jederzeit diese Hinweise zum Datenschutz unter Beachtung der datenschutzrechtlichen Vorgaben zu ändern.
            </p>

        </>
    )
}

export default Disclaimer
