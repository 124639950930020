import React from 'react'
import { DataHelper } from '../helper/dataHelper';

function JobOffers() {
    const filteredJobs = DataHelper.aJobs.filter(element => element.Wert === "Angezeigt");

    return (
        <div className="text-container font-size-15 job-offers">
            <div className="middle">
                <div className='flex-container no-center no-reverse'> 
                    <div className='left'>
                     <h1>Stellenangebote</h1><br/>
                        In unseren Einrichtungen werden folgende Stellen besetzt:
                        <ul>
                            <li>Hauswirtschaftler/in</li>
                            <li>Erzieher/in</li>
                            <li>Reinigungskraft</li>
                        </ul>
                        <br/>
                        Initiativbewerbungen sind gerne erwünscht. Unsere allgemeinen Anforderungen sind:
                        <ul>
                            <li>Sehr gute fachliche Kenntniss im jeweiligen Bereich</li>
                            <li>Belastbarkeit</li>
                            <li>Teamfähigkeit</li>
                            <li>Für Erzieher/in und Hauswirtschaftler/in wird ein PKW-Führerschein vorausgesetzt</li>
                        </ul> 
                        <br/>

                        Was wir bieten:
                        <ul>
                            <li>Ein nettes Kollegium</li>
                            <li>Die Möglichkeit in Voll-/ oder Teilzeit zu arbeiten</li>
                        </ul> 
                        <br/><br/>
                    </div>
                    <div className='right'>
                        <h1>Aktuell gesucht:</h1><br/>

                        {filteredJobs.length === 0 ? (
                            <>
                                <h1>Aktuell keine Stellenangebote</h1>
                                <br/>
                            </>
                        ) : (
                            filteredJobs.map((element, index) => (
                                <div key={index}>
                                    <h1>{element.Name}</h1>
                                    <p>{element.Text}</p><br /><br />
                                </div>
                            ))
                        )}
                        
                        
                        Ihre Bewerbung (mit Rückumschlag) - gerne auch per E-Mail - richten Sie bitte an:
                        <div className='text-center'>
                            <b>Stiftung Haus Pius XII</b><br/>
                            Herrn Michael A. Lencz<br/>
                            Sophie-Charlotte-Straße 31<br/>
                            D-14169 Berlin<br/>
                            E-Mail: <a href="mailto:geschaeftsfuehrung@stiftung-haus-pius.de?subject=Aus Homepage Kontakt">geschaeftsfuehrung@stiftung-haus-pius.de</a>
                        </div>
                    </div>
                </div>

               

            </div>
        </div>
    )
}

export default JobOffers
